import React from "react";
import "./Footer.css";
import Footertext2 from "./images/footer-text-2.svg";
import Footersmiley from "./images/f-smile.svg";
// import FeelGood from "./images/feel-good.svg";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { footerVal, getStylesLan } from "../../utils/Constants";
import FooterScroller from "./FooterScroller";
import { FaArrowRightLong } from "react-icons/fa6";

export const Footer = () => {
    const footerData = useSelector((store) => store.storeSettings?.footer);
    const scrollerData = useSelector((store) => store.storeSettings?.scrolling);

    if (!footerData || !scrollerData) {
        return;
    }

    const styles = getStylesLan(
        footerData,
        "footer_background_color",
        "footer_text_color"
    );

    return (
        <div>
            {scrollerData.scrolling_banner_status && (
                <FooterScroller
                    scrollerData={scrollerData}
                    footerData={footerData}
                />
            )}

            {/* Footer Section */}
            <section className="footer-Section" style={styles.fullBg}>
                <footer className="Footer-main container-fluid-g-0">
                    <div className="common-wrap">
                        <div className="footer-inner container">
                            <div className="footer-top">
                                {footerData?.payment_method_status && (
                                    <Link to="/" className="footer-logo ">
                                        <img
                                            src={
                                                footerData?.payment_method_img
                                            }
                                            alt="footer-logo"
                                        />
                                    </Link>
                                )}
                                <div className="footer-headings">
                                    <h1 >
                                        {footerData?.bottom_contact}
                                    </h1>
                                </div>
                            </div>

                            <div
                                style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                    height: "1px",
                                    marginTop: "1.8rem",
                                }}
                            ></div>

                            <div className="footer-widget-wrap">
                                <div className="links-wrapper">
                                    {footerData &&
                                        footerVal.map((val) => {
                                            const blockStatus =
                                                footerData[
                                                `block${val}_status`
                                                ];

                                            if (
                                                blockStatus !== undefined &&
                                                blockStatus
                                            ) {
                                                return (
                                                    <div
                                                        key={val}
                                                        className="footer-widget-items"
                                                    >
                                                        {footerData?.[
                                                            `block${val}_title`
                                                        ]?.en && (
                                                                <h6
                                                                    style={
                                                                        styles.color
                                                                    }
                                                                >
                                                                    {
                                                                        footerData?.[
                                                                            `block${val}_title`
                                                                        ]?.en
                                                                    }
                                                                </h6>
                                                            )}
                                                        <ul className="list-unstyled">
                                                            {footerVal.map(
                                                                (idx) => {
                                                                    return (
                                                                        footerData?.[
                                                                            `block${val}_sub_title${idx}`
                                                                        ]
                                                                            ?.en && (
                                                                            <li
                                                                                key={
                                                                                    idx
                                                                                }
                                                                                className=""
                                                                            >
                                                                                <Link
                                                                                    to={
                                                                                        footerData?.[
                                                                                        `block${val}_sub_link${idx}`
                                                                                        ]
                                                                                    }
                                                                                    className="fshopcategory text-decoration-none"
                                                                                    style={
                                                                                        styles.color
                                                                                    }
                                                                                // onClick={() => window.location = 'design@gmail.com'}

                                                                                >
                                                                                    {
                                                                                        footerData?.[
                                                                                            `block${val}_sub_title${idx}`
                                                                                        ]
                                                                                            ?.en
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    </div>
                                                );
                                            }

                                            // If blockStatus is falsy or undefined, return null
                                            return null;
                                        })}
                                </div>

                                <div className="footer-widget newsletter-widget">
                                    <p
                                        className="sign-up"
                                        style={styles.color}
                                    >
                                        Sign up
                                    </p>
                                    <div className="footer-newsletter-wrap">
                                        <form
                                            id="email_signup"
                                            className="klaviyo_gdpr_embed_WbhN5R"
                                            action=""
                                            data-ajax-submit=""
                                            method="GET"
                                            target="_blank"
                                            noValidate="noValidate"
                                        >
                                            <input type="hidden" name="g" />
                                            <input
                                                type="hidden"
                                                name="$fields"
                                            />
                                            <input
                                                type="hidden"
                                                name="$list_fields"
                                            />
                                            <div className="sign-up-input">
                                                <input
                                                    className="sign-up-input-field"
                                                    type="email"
                                                    name="email"
                                                    id="k_id_email"
                                                    placeholder="Your email"
                                                />
                                                <button
                                                    className="input-field-icon"
                                                    style={styles.color}
                                                    disabled
                                                >
                                                    Sign Up{" "}
                                                    <FaArrowRightLong id="arrow" />
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* {footerData?.social_links_status && (
                                        <div className="socialIcon">
                                            {footerData?.social_twitter && (
                                                <Link
                                                    className="text-decoration-none"
                                                    to={footerData?.social_twitter}
                                                >
                                                    <FaTwitter className="icon" />
                                                </Link>
                                            )}
                                            {footerData?.social_facebook && (
                                                <Link
                                                    className="text-decoration-none"
                                                    to={footerData?.social_facebook}
                                                >
                                                    <FaFacebook className="icon" />
                                                </Link>
                                            )}

                                            {footerData?.social_pinterest && (
                                                <Link
                                                    className="text-decoration-none"
                                                    to={footerData?.social_pinterest}
                                                >
                                                    <FaInstagram className="icon" />
                                                </Link>
                                            )}
                                        </div>
                                    )} */}
                                </div>
                            </div>


                            <div className="footer-bottom d-flex">
                                <nav className="footer-nav">
                                    <ul className="Policieslists">
                                        <li>
                                            <Link
                                                className="Policiestext text-decoration-none"
                                                to="policies/privacy-policy"
                                                style={styles.color}
                                            >
                                                Privacy Policy
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="Policiestext text-decoration-none"
                                                to="policies/refund-policy"
                                                style={styles.color}
                                            >
                                                Refund Policy
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                className="Policiestext text-decoration-none"
                                                to="policies/terms-of-service"
                                                style={styles.color}
                                            >
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>

                                <div className={!footerData?.social_links_status ? "footer-widget-mobile newsletter-widget" : "d-none"}>
                                    <div className="socialIcon">
                                        {footerData?.social_twitter && (
                                            <Link
                                                className="text-decoration-none"
                                                to={footerData?.social_twitter}
                                            >
                                                <FaTwitter className="icon" />
                                            </Link>
                                        )}
                                        {footerData?.social_facebook && (
                                            <Link
                                                className="text-decoration-none"
                                                to={footerData?.social_facebook}
                                            >
                                                <FaFacebook className="icon" />
                                            </Link>
                                        )}

                                        {footerData?.social_pinterest && (
                                            <Link
                                                className="text-decoration-none"
                                                to={footerData?.social_pinterest}
                                            >
                                                <FaInstagram className="icon" />
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="footer-bottom-text">
                                    <img
                                        className="Footertext2"
                                        src={Footertext2}
                                        alt="footer-text"
                                    />
                                    <div className="footer-smileys">
                                        <div className="fsmiley">
                                            <img
                                                src={Footersmiley}
                                                alt="abc"
                                            />
                                        </div>
                                        <div className="fsmiley">
                                            <img
                                                src={Footersmiley}
                                                alt="abc"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </section>
        </div>
    );
};
