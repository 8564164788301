import React, { useEffect, useState } from "react";
import "../Accounts/Ollys-Login/Login.css";
import "./Shop.css";
export const ShopBanner = ({ shopData, styles }) => {
    const [showValue, setShowValue] = useState(window.innerWidth);

    useEffect(() => {
        const handleWidth = () => {
            setShowValue(window.innerWidth);
        };
        window.addEventListener("resize", handleWidth);
        return () => {
            window.removeEventListener("resize", handleWidth);
        };
    });

    return (
        <div className="shop-banner-container">
            {/* <div className="shop-page-hr-blue-line"></div> */}
            <section
                className="Shop-section container-fluid g-0 mx-auto"
                style={styles.fullBg}
            >
                <div className="ShopPretzelThins-section">
                    <div
                        className={
                            showValue > 1000
                                ? "Shop-banner"
                                : "Shop-banner d-flex flex-column"
                        }
                    >
                        {showValue < 1000 ? (
                            <div>
                                <img
                                    className="shopbannerimge"
                                    src={shopData.icon}
                                    alt="jadns"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="Shopbanner-left">
                            {shopData?.name?.en && (
                                <h1
                                    className="Shopbanner-header wow animate__animated animate__fadeInUp"
                                    style={styles.color}
                                >
                                    {shopData?.name?.en}
                                </h1>
                            )}
                            {shopData?.description?.en && (
                                <div
                                    className="Shopbanner-para wow animate__animated animate__fadeInUp"
                                    style={styles.color}
                                >
                                    {shopData?.description?.en}
                                </div>
                            )}
                            <div className="ShopBiconsmain-wapper">
                                <div className="ShopBiconsmain wow animate__animated animate__fadeInUp">
                                    {shopData?.icon1 && (
                                        <div className="SBicon">
                                            <img
                                                src={shopData?.icon1}
                                                alt="avb"
                                            />
                                        </div>
                                    )}
                                    {shopData?.icon2 && (
                                        <div className="SBicon">
                                            <img
                                                src={shopData?.icon2}
                                                alt="vsdk"
                                            />
                                        </div>
                                    )}
                                    {shopData?.icon3 && (
                                        <div className="SBicon">
                                            <img
                                                src={shopData?.icon3}
                                                alt="sdnn"
                                            />
                                        </div>
                                    )}
                                    {shopData?.icon4 && (
                                        <div className="SBicon">
                                            <img
                                                src={shopData?.icon4}
                                                alt="dskjb"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="shop-page-hr-blue-line-bottom"></div>
                            </div>
                        </div>
                        {showValue > 1000 ? (
                            <div className="Shopbanner-right">
                                <img
                                    className="shopbannerimge"
                                    src={shopData.icon}
                                    alt="jsdfn"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};
