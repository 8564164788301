import React, { useEffect, useState } from "react";
import { Navigation2 } from "../Navigation/Navigation2";
import "../Accounts/Accounts.css";
import "./Profile.css";
import { SubHeader } from "../Accounts/SubHeader/SubHeader";
import { useDispatch } from "react-redux";
import { updateCustomer } from "../../Redux/actions/authServices";

const Profile = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();

        let userInfo = {
            name: e.target.name.value + " " + e.target.lastName.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            country: e.target.country.value,
            state: e.target.state.value,
            city: e.target.city.value,
            address: e.target.address1.value,
            address1: e.target.address2.value,
            pincode: e.target.pincode.value,
        };

        dispatch(updateCustomer(userInfo));
    };

    return (
        <>
            <SubHeader />
            <div className="container-fluid">
                <section className="Profile-section">
                    <div className="profile-form-wapper">
                        <h1 className="my-5 text-center text-uppercase accoount-profile-heading">
                            Account Address
                        </h1>
                        <form onSubmit={handleSubmit} className="form-div">
                            <div className="personal-info-section">
                                <h4>Personal information</h4>
                                <div className="ProfileInputblock">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        defaultValue={
                                            user?.name.split(" ").length
                                                ? user?.name.split(" ")[0]
                                                : ""
                                        }
                                        name="name"
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        defaultValue={
                                            user?.name.split(" ").length
                                                ? user?.name.split(" ")[1]
                                                : ""
                                        }
                                        name="lastName"
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label htmlFor="">Phone Number</label>
                                    <input
                                        type="text"
                                        defaultValue={user?.phone}
                                        name="phone"
                                        placeholder="Add number"
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label>E-mail</label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={user?.email}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="shipping-address-section">
                                <h4>Shipping</h4>
                                <div className="ProfileInputblock">
                                    <label htmlFor="">Address1</label>
                                    <input
                                        defaultValue={user?.address}
                                        type="text"
                                        name="address1"
                                        placeholder="Street name, Sector"
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label htmlFor="">Address2</label>
                                    <input
                                        defaultValue={user?.address1}
                                        type="text"
                                        name="address2"
                                        placeholder="House No., Landmark"
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label htmlFor="">Country/Region</label>
                                    <input
                                        type="text"
                                        name="country"
                                        defaultValue={"India"}
                                        disabled
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label>State</label>
                                    <input
                                        defaultValue={user?.state}
                                        type="text"
                                        name="state"
                                        placeholder="State"
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label htmlFor="">City</label>
                                    <input
                                        defaultValue={user?.city}
                                        type="text"
                                        name="city"
                                        placeholder="City"
                                    />
                                </div>
                                <div className="ProfileInputblock">
                                    <label placeholder="Pincode">
                                        Postal/PinCode
                                    </label>
                                    <input
                                        defaultValue={user?.pincode}
                                        type="number"
                                        name="pincode"
                                        placeholder="Add ZIP code"
                                    />
                                </div>
                                <div className="profile-btn-group">
                                    <button
                                        className="Profilebtn"
                                        type="submit"
                                    >
                                        Update
                                    </button>
                                    <button className="Profilebtn">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Profile;
