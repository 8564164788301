import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import OffCanvas from "react-bootstrap/Offcanvas";
import ProgressBar from "react-bootstrap/ProgressBar";
import { MdOutlineShoppingBag } from "react-icons/md";
import { BiSolidTrashAlt } from "react-icons/bi";
import Backarrow from "./images/back.svg";
import "./Cart.css";
import "../Accounts/Ollys-Login/Login.css";
import "../Navigation/Navigation1.css";
import "../Navigation/Navigation2.css";
import Star from "./images/star.svg";
import requests from "../../Services/httpService";
import CartQuantity from "./CartQuantity";
import emptyCart from "../../assets/emptyCart.png";
import { deleteCartLocal } from "../../Redux/actions/cartServices";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
export const Cart = () => {

    const { cartUpdate } = useSelector((store) => store.auth);

    const [showcart, setShowCart] = useState(false);
    const [cartData, setCartData] = useState([]);
    const [totalCartVal, setTotalCartVal] = useState(0);
    const [cartTotalQty, setTotalCartQty] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    const handleShow = (e) => {
        e.preventDefault();
        setShowCart(true);
    };

    const handleClose = () => setShowCart(false);
    const user = JSON.parse(localStorage.getItem("user"));

    const fetchCarts = async () => {
        if (!user?.token) {
            const localCarts = JSON.parse(localStorage.getItem("carts"));

            if (!localCarts) {
                setCartData([]);
                setTotalCartVal(0);
                setTotalCartQty(0);
                return;
            }

            let totalCartAmount = localCarts.reduce((sum, currVal) => {
                return sum + currVal.price * currVal.quantity;
            }, 0);

            const totalItemQty = localCarts?.reduce((qty, currVal) => {
                return qty + currVal.quantity;
            }, 0);

            setCartData(localCarts);
            setTotalCartVal(totalCartAmount);
            setTotalCartQty(totalItemQty);

            return;
        }

        const res = await requests.get("/cart");
        let totalCartAmount = res?.carts.reduce((sum, currVal) => {
            return (sum += currVal.price * currVal.quantity);
        }, 0);

        const totalItemQty = res.carts?.reduce((qty, currVal) => {
            return qty + currVal.quantity;
        }, 0);

        setTotalCartQty(totalItemQty);
        setCartData(res.carts);
        setTotalCartVal(totalCartAmount);
    };

    useEffect(() => {
        fetchCarts();
    }, [showcart, cartTotalQty, cartUpdate]);

    const deleteCartItem = (cartId) => {
        if (!user?.token) {
            deleteCartLocal(cartId);
            fetchCarts();
            return;
        }

        const deleteCartItemById = async (cartId) => {
            await requests.delete(`/cart/${cartId}`);
            fetchCarts();
        };

        deleteCartItemById(cartId);
    };

    const handleCheckout = () => {
        if (!cartData?.length) {
            toast.error("Please add Some item to Cart !!");
            return;
        }
        navigate("/checkouts");
    };

    // const changeHeaderLinksColor =
    //     location.pathname === "/collections/all" || "/accounts/profile";
    // console.log(changeHeaderLinksColor);

    return (
        <div className="cart-wapper">
            <button onClick={handleShow} className="accountIcon nav-link">
                <MdOutlineShoppingBag
                    className={"cart-bag-icon"}
                // className={
                //     changeHeaderLinksColor
                //         ? "change-cart-bag-icon-color-to-blue cart-bag-icon"
                //         : "cart-bag-icon"
                // }
                />
                <div className="cart-qty">
                    <span>{cartTotalQty}</span>
                </div>
            </button>
            {/* <button onClick={handleShow} className='accountIcon nav-link '><input type="text" value={cartProductData.length} className="Showprouctno" readOnly /></button> */}
            <OffCanvas
                show={showcart}
                placement="end"
                onHide={handleClose}
                name="end"
                className="cart-wapper-container"
            >
                <OffCanvas.Header className="accountIcon CartmainHeader">
                    <button onClick={handleClose} className="cartClosebtn">
                        <img src={Backarrow} alt={Backarrow} />
                    </button>
                    <OffCanvas.Title className="Cartheader">
                        Your Cart
                    </OffCanvas.Title>
                </OffCanvas.Header>
                <OffCanvas.Body>
                    {totalCartVal < 999 ? (
                        <p className="ProgressBartext">
                            <img src={Star} alt={Star} />
                            <span />{" "}
                            <strong className="ProgressBartext">
                                REWARDS
                            </strong>{" "}
                            Spend another <span>&#8377; </span>{999 - totalCartVal} to qualify
                            for free shipping
                        </p>
                    ) : totalCartVal >= 999 ? (
                        <p className="ProgressBartext">
                            <img src={Star} alt={Star} />
                            <span />{" "}
                            <strong className="ProgressBartext">
                                REWARDS
                            </strong>{" "}
                            Great! You have got free shipping!
                        </p>
                    ) : (
                        <p className="ProgressBartext">
                            <img src={Star} alt={Star} />
                            <span />{" "}
                            <strong className="ProgressBartext">
                                REWARDS
                            </strong>{" "}
                            Spend atleast <span>&#8377; </span>25.00 to qualify
                            for free shipping
                        </p>
                    )}
                    {totalCartVal === 0 ? (
                        <ProgressBar now={0} />
                    ) : totalCartVal === 399 ? (
                        <ProgressBar now={25} />
                    ) : totalCartVal <= 666 ? (
                        <ProgressBar now={50} />
                    ) : totalCartVal < 999 ? (
                        <ProgressBar now={75} />
                    ) : (
                        <ProgressBar now={100} />
                    )}
                    <div className="CartProduct">
                        {cartData.length
                            ? cartData.map((eachCart, idx) => {
                                return (
                                    <>
                                        <div className="Cartgrid " key={idx}>
                                            <div className="cartproductimg">
                                                <img
                                                    src={eachCart?.image}
                                                    alt={eachCart?.image}
                                                />
                                            </div>
                                            <div className="cartProductremaing">
                                                <div className="cartRemainpart1">
                                                    <div className="cartProductName">
                                                        {eachCart?.title}
                                                    </div>
                                                    <div
                                                        className="cartProductDel"
                                                        onClick={() =>
                                                            deleteCartItem(
                                                                eachCart?._id
                                                                    ? eachCart?._id
                                                                    : eachCart
                                                            )
                                                        }
                                                    >
                                                        <BiSolidTrashAlt />
                                                    </div>
                                                </div>
                                                {eachCart?.attribute ? (
                                                    <div className="cartProductSize">
                                                        {eachCart?.attribute}
                                                    </div>
                                                ) : null}
                                                {eachCart?.subscription && (
                                                    <div>
                                                        {" "}
                                                        {
                                                            eachCart?.subscription
                                                        }{" "}
                                                    </div>
                                                )}
                                                <div className="cartRemainPart2">
                                                    <CartQuantity
                                                        eachCart={eachCart}
                                                        cartId={eachCart?._id}
                                                        quantity={
                                                            eachCart?.quantity
                                                        }
                                                        fetchCarts={fetchCarts}
                                                    />
                                                    <div className="cartProductPrice">
                                                        &#8377;
                                                        {eachCart?.price}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blue-hr"></div>
                                    </>
                                );
                            })
                            :
                            <div className="empty-cart-relative-div">
                                <Link to="/collections/all" onClick={handleClose}>Back to shop</Link>
                                <div className="empty-cart-img">
                                    <img src={emptyCart} alt="empty-cart-img" />
                                </div>
                            </div>
                        }
                    </div>
                </OffCanvas.Body>
                <div className="CartOrder-section w-100">
                    <div className="CartOrder-details">
                        <div className="CartOrder-header">Order Total</div>
                        <div className="CartOrder-Price">
                            &#8377;
                            {totalCartVal}
                        </div>
                    </div>
                    <div className="Cartbtn">
                        <button
                            onClick={handleCheckout}
                            className="Checkoutbutton"
                        >
                            Checkout
                        </button>
                    </div>
                </div>
            </OffCanvas>
        </div>
    );
};
