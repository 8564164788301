import React, { useEffect, useState } from 'react'
import "./AllOrders.css"
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import requests from '../../../Services/httpService'
import emptyOrderList from "../../../assets/emptyOrderList.png"

const AllOrders = () => {
    const [allOrderData, setAllOrderData] = useState([])
    const user = JSON.parse(localStorage.getItem("user"));

    const getDeliverdColor = (status) => {
        const color = {
            Pending: "warning",
            Delivered: "success",
            Processing: "primary",
            Cancel: "danger",
        };
        return color[status];
    };

    useEffect(() => {
        (async () => {
            setAllOrderData(await requests.get(`/orders/customer/${user._id}`))
        })()
    }, [user._id]);

    const prepareAllOrderList = []
    const calculateTotal = (cartData) => {
        return cartData?.reduce((prevTotal, item) => {
            item.subscription ? (prevTotal.Subscription += item?.quantity) : (prevTotal.Onetime += item?.quantity);
            return prevTotal;
        }, { Subscription: 0, Onetime: 0 });
    }

    allOrderData?.forEach((order) => {
        const cartItem = order?.cart[0];
        prepareAllOrderList.push({
            id: cartItem?._id,
            title: cartItem?.title,
            image: cartItem?.image,

            orderDate: (new Date(order?.createdAt)).toLocaleString(),
            onetimePurchesQty: calculateTotal(order?.cart).Onetime,
            subscriptionPurchesQty: calculateTotal(order?.cart).Subscription,

            status: order?.status,
            invoice: order?.invoice,
            amount: order?.total,
        });
    });

    return (
        <div className='all-orders-main-wapper'>
            <h2 className='all-order-heder'>Order History</h2>
            <div className="all-order-info-wapper">
                <h1>Your Orders</h1>
                <div className='all-order-product-wappper'>
                    {prepareAllOrderList.length !== 0 ? prepareAllOrderList?.map((item) => {
                        return <Link to={`/accounts/ordersDetails/${item.invoice}`} className="all-order-info-grid" key={item.id}>
                            <div className='product-img-div'>
                                <img src={item.image} alt={item.title} />
                            </div>
                            <div className='product-price-amount-order-date'>
                                <h2>{item.title}</h2>
                                <h3>Invoice : <span>{item.invoice}</span></h3>
                                <h3>Amount : <span>&#8377; {item.amount}</span></h3>
                                <h3>Order Date : <span> {item.orderDate}</span></h3>
                            </div>
                            <div className='delivery-status-and-prodouct-qty'>
                                <Badge pill bg={getDeliverdColor(item.status)}
                                    className='delivery-status'>
                                    {item.status}
                                </Badge>
                                <p>Onetime : <span> {item.onetimePurchesQty}</span></p>
                                <p>Subscription : <span> {item.subscriptionPurchesQty}</span></p>
                            </div>
                        </Link>
                    }) :
                        <div className='order-history-empty-list-msg'>
                            <img src={emptyOrderList} alt='order-list-empty' />
                            <Link to="/collections/all">Back to shop</Link>
                            <div className='empty-roder-list-text'>
                                <h3>Nothing !!</h3>
                                <p>Your order list is empty.</p>
                            </div>
                        </div>}
                </div>
            </div>
        </div >
    )
}

export default AllOrders
