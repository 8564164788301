import React from 'react'
import { useSelector } from 'react-redux';
import FeaturePromo from '../Home/FeaturePromo/Featurepromo';
import "./ContactUs.css"
import { FaPhoneAlt } from "react-icons/fa";

const ContactUs = () => {

    const homeData = useSelector(store => store?.storeSettings?.home);

    if (!homeData) {
        return;
    }

    const handleMap = (e) => {
        console.log(e);
    }

    // console.log(homeData);

    return (
        <div className=''>
            <div className='contact-div'>
                <h1 className='contact-heading wow animate__animated animate__fadeInUp'> Go-Gourment </h1>

                <p className='contact-descmain wow animate__animated animate__fadeInUp d-flex flex-column justify-content-center align-items-center gap-2 pl-md-5'>
                    <div className='w-75 d-flex flex-column align-items-start' style={{ justifyContent: "center" }}>
                        <span
                            style={{ fontWeight: "light", fontSize: "0.7rem" }}
                        >
                            Name of the Firm:
                        </span>
                        <span>
                            Seedlink Overseas Pvt. Ltd.
                        </span>
                    </div>
                    <div className='w-75 d-flex flex-column align-items-start' style={{ justifyContent: "center" }}>
                        <span
                            style={{ fontWeight: "light", fontSize: "0.7rem" }}
                        >
                            Nature of the Firm:
                        </span>
                        <span>
                            Partnership
                        </span>
                    </div>
                    <div className='w-75 d-flex flex-column align-items-start' style={{ justifyContent: "center" }}>
                        <span
                            style={{ fontWeight: "light", fontSize: "0.7rem" }}
                        >
                            Name of Partners:
                        </span>
                        <span className=''
                            style={{ display: "flex", flexWrap: "wrap", gap: "5px", justifyContent: "start", whiteSpace: "wrap", lineHeight: "2.5rem", textAlign: "left" }}
                        >
                            Arpit Chopra, Aayush Talesara, Somya Chopra, & Anish Talesara.
                        </span>
                    </div>
                    <div className='w-75 d-flex flex-column align-items-start' style={{ justifyContent: "center" }}>
                        <span
                            style={{ fontWeight: "light", fontSize: "0.7rem" }}
                        >
                            Address of the Firm:
                        </span>
                        <span className=''
                            style={{ display: "flex", flexWrap: "wrap", gap: "5px", justifyContent: "start", whiteSpace: "wrap", lineHeight: "2.5rem", textAlign: "left" }}
                        >
                            71 Hanuman Nagar, Baghana Neemuch.
                        </span>
                    </div>
                </p>
                {/* <div className='contact-descother  wow animate__animated animate__fadeInUp'><span className='contact-descother-icon'>
                    <FaPhoneAlt /></span> 465645454546 <br /> <span className='contact-descother  wow animate__animated animate__fadeInUp'>Scheme no.78, VijayNagar,Indore(M.P.)</span></div> */}
            </div>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471.7942877212945!2d74.8491381165254!3d24.458638593342556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396673a1ed4eb247%3A0x2ecb6b29189b12fe!2sHanuman%20Nagar%20Park!5e0!3m2!1sen!2sin!4v1718619821677!5m2!1sen!2sin" loading="lazy" onClick={handleMap} className='contact-map' title='jsdfn'></iframe>
            </div>
         
            {/* {homeData && <FeaturePromo featurePromoData={homeData} />} */}
        </div>
    )
}

export default ContactUs;