import React from "react";
import { featurePromo } from "../../../utils/Constants";
export const Productoverview = ({ testimonialData, styles }) => {
    if (!testimonialData) {
        return;
    }

    return (
        <>
            {testimonialData.testimonial_status ? (
                <section
                    className="Product-OverviewSection text-center contianer-fluid g-0 wow animate__animated animate__fadeInUp"
                    style={styles?.fullBg}
                >
                    <div className="product-overview-inner-wrap">
                        <div className="common-wrap container g-0 mx-auto clear">
                            <div className="product-overview-inner">
                                <div className="product-overview-title ">
                                    {testimonialData.testimonial_title_header
                                        .en && (
                                            <h2
                                                className="text-center wow animate__animated animate__fadeInUp"
                                                style={styles?.color}
                                            >
                                                {
                                                    testimonialData
                                                        .testimonial_title_header.en
                                                }
                                            </h2>
                                        )}
                                    {testimonialData?.testimonial_subtitle
                                        ?.en && (
                                            <h4
                                                className="text-center wow animate__animated animate__fadeInUp"
                                                style={styles?.color}
                                            >
                                                {
                                                    testimonialData
                                                        .testimonial_subtitle?.en
                                                }
                                            </h4>
                                        )}
                                </div>
                                <div className="product-overview-item-wrap container g-0 mx-auto d-flex">
                                    {featurePromo.map((val) => {
                                        if (
                                            !testimonialData[
                                            `testimonial_banner_img_${val}`
                                            ]
                                        ) {
                                            return;
                                        }
                                        return (
                                            <div
                                                key={val}
                                                className="product-overview-item"
                                            >
                                                <div className="product-overview-item-logo">
                                                    <img
                                                        className="pologoimg"
                                                        src={
                                                            testimonialData[
                                                            `testimonial_banner_img_${val}`
                                                            ]
                                                        }
                                                        alt="product-overview-logo"
                                                    />
                                                </div>
                                                <div className="product-overview-item-content">
                                                    {testimonialData[
                                                        `testimonial_title_${val}`
                                                    ]?.en && (
                                                            <h6
                                                                className="poheader"
                                                                style={
                                                                    styles?.color
                                                                }
                                                            >
                                                                {
                                                                    testimonialData[
                                                                        `testimonial_title_${val}`
                                                                    ]?.en
                                                                }{" "}
                                                            </h6>
                                                        )}
                                                    {testimonialData[
                                                        `testimonial_description_${val}`
                                                    ]?.en && (
                                                            <p
                                                                className="pocontent"
                                                                style={
                                                                    styles?.color
                                                                }
                                                            >
                                                                {
                                                                    testimonialData[
                                                                        `testimonial_description_${val}`
                                                                    ]?.en
                                                                }
                                                            </p>
                                                        )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : null}
        </>
    );
};
