import React from "react";
import { useSelector } from "react-redux";
import "./Privacypolicy.css";
export const Privacypolicy = () => {
    const privacyPolicyData = useSelector(
        (store) => store?.storeSettings?.privacy_policy
    );

    return (
        <div>
            {privacyPolicyData?.status ? (
                <>
                    <h1 className="m-4 p-5">
                        {" "}
                        {privacyPolicyData?.title?.en}{" "}
                    </h1>
                    <div
                        className="Privacypolicy-Container"
                        dangerouslySetInnerHTML={{
                            __html: privacyPolicyData?.description?.en,
                        }}
                    ></div>
                </>
            ) : (
                <h1> Data Not Available </h1>
            )}
        </div>
    );
};
