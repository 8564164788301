import React, { useEffect, useState } from 'react'
import '../Ollys-Login/Login.css'
import './OrderHistory.css'
import { SubHeader } from '../SubHeader/SubHeader'
import AllOrders from './AllOrders';

export const OrderHistory = () => {
    return (
        <div>
            <div className="container">
                <SubHeader />
                <AllOrders />
            </div>
        </div>
    )
}
