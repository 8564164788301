import React, { useEffect, useState } from "react";
import { MdUnsubscribe } from "react-icons/md";
import requests from "../../../Services/httpService";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import "./OrderHistory.css"
import { Link, useParams } from "react-router-dom";
import { RiDownload2Fill } from "react-icons/ri";
import { Invoice } from "../../Invoice/Invoice";


const OrdersDetails = ({ subscription = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const [allOrderData, setAllOrderData] = useState([])
  const user = JSON.parse(localStorage.getItem("user"));
  const { invoiceID } = useParams();

  const handleInvoice = () => {
    setFullscreen(true);
    setShowModal(true);
  }

  const handleCancelSubscription = async (id) => {
    // await requests.put("/customer/subscription-inactive", {
    //   subscriptionId: id,
    // });
    // fetchUserOrder();
  };

  const getDeliverdColor = (status) => {
    const color = {
      Pending: "warning",
      Delivered: "success",
      Processing: "primary",
      Cancel: "danger",
    };
    return color[status];
  };

  useEffect(() => {
    (async () => {
      const res = await requests.get(`/orders/customer/${user._id}`);
      res?.forEach((order) => {
        ((order?.invoice) === Number(invoiceID)) && setAllOrderData((prev) => [...prev, order]);
      });
    })()
  }, [user._id, invoiceID]);

  return (
    <div className="OrderProfile-section">
      <h1 className='Order-History-Heading text-center'>Order History</h1>
      <div className="back-to-all-order-link">
        <Link to="/accounts/order-history">Back to all order</Link>
      </div>
      <div className="OrderDetails">
        <div className="Order-details-header">
          <h3 >All Order Invoice</h3>
          <RiDownload2Fill title="Download Invoice" onClick={() => handleInvoice()} />
        </div>
        {allOrderData[0]?.cart?.map((item) => {
          return <div className="OrderedProductBody" key={item._id}>
            <div className="OrderedProduct-left">
              <div className="OrderedProductimage">
                <img src={item?.image} alt={item?.image} />
                <div className="orderedQuantity">{item?.quantity}</div>
              </div>
            </div>
            <div className="OrderedProduct-right">
              <div className="OrderedProduct-content">
                <div className="orderProductName">{item?.title}</div>
                <div className="orderedProductSize">
                  {item?.attribute}
                </div>
                {item?.duration && (
                  <div className="orderedProductSize">
                    {item?.duration}
                  </div>
                )}
                <div className="orderedProductBottom">
                  <div className="OrderedProductPrice">
                    Price : <span>&#8377; </span>
                    {item?.price}
                  </div>
                  <div className="d-flex gap-1">
                    {item?.duration && item?.status === "Active" ? (
                      <div>
                        <button
                          onClick={() => handleCancelSubscription(item._id)}
                          title="Cancle Subscription"
                          className="cancelSubsbtn"
                          style={
                            item?.duration
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <span>
                            <MdUnsubscribe />
                          </span>
                        </button>
                      </div>
                    ) : ""}

                    <Stack direction="horizontal" gap={2}>
                      {subscription && item?.status ? (
                        <Badge
                          pill
                          bg={
                            item.status === "Active"
                              ? "success"
                              : "danger"
                          }
                        >
                          {item?.status}
                        </Badge>
                      ) : (
                        <Badge pill bg={getDeliverdColor(item?.status)}>
                          {item?.status}
                        </Badge>
                      )}
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        })}
        {showModal && <Invoice showModal={showModal} fullscreen={fullscreen} allOrderData={allOrderData} />}
      </div >
    </div>
  );

};

export default OrdersDetails;


// <>
//         <div className="OrderedProductBody border-5" key={idx}>
//           <div className="OrderedProduct-left">
//             <div className="OrderedProductimage">
//               <img src={eachItem?.cart?.image} alt={eachItem?.image} />
//               <div className="orderedQuantity">{eachItem?.cart?.quantity}</div>
//             </div>
//           </div>
//           <div className="OrderedProduct-right">
//             <div className="OrderedProduct-content">
//               <div className="orderProductName">{eachItem?.title}</div>
//               <div className="orderedProductSize">
//                 {eachItem?.attribute}
//               </div>
//               {eachItem?.duration && (
//                 <div className="orderedProductSize">
//                   {eachItem?.duration}
//                 </div>
//               )}
//               <div className="orderedProductBottom">
//                 <div className="OrderedProductPrice">
//                   Price: <span>&#8377; </span>
//                   {eachItem?.price}
//                 </div>
//                 <div className="d-flex gap-1">
//                   {eachItem?.duration && eachItem?.status === "Active" ? (
//                     <div>
//                       <button
//                         onClick={() => handleCancelSubscription(eachItem)}
//                         title="Cancle Subscription"
//                         className="cancelSubsbtn"
//                         style={
//                           eachItem?.duration
//                             ? { display: "block" }
//                             : { display: "none" }
//                         }
//                       >
//                         <span>
//                           <MdUnsubscribe />
//                         </span>
//                       </button>
//                     </div>
//                   ) : (
//                     ""
//                   )}
//                   <div>
//                     <button onClick={() => handleInvoice(eachItem)} title="Download Invoice"><RiFileDownloadFill /></button>
//                   </div>
//                   <Stack direction="horizontal" gap={2}>
//                     {subscription && eachItem?.status ? (
//                       <Badge
//                         pill
//                         bg={
//                           eachItem.status === "Active"
//                             ? "success"
//                             : "danger"
//                         }
//                       >
//                         {eachItem?.status}
//                       </Badge>
//                     ) : (
//                       <Badge pill bg={getDeliverdColor(eachItem.status)}>
//                         {eachItem?.status}
//                       </Badge>
//                     )}
//                   </Stack>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </> 
