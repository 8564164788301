import { createSlice } from "@reduxjs/toolkit";
const user = JSON.parse(localStorage.getItem("user"));

const authSlice = createSlice({
    
    name: "auth",

    initialState: {
        user: (user || null),
        cartUpdate: false,
    },

    reducers: {

        setUser: (state,action) => {
            state.user = action.payload
        },

        removeUser: (state, action) => {
            state.user = null
        },

        updateCart: (state, action) => {
            console.log(state.cartUpdate);
            state.cartUpdate = (!state.cartUpdate);
        }

    }

});

export const { setUser , removeUser , updateCart } = authSlice.actions;
export default authSlice.reducer;

