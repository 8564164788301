import React, { useEffect, useState } from "react";
import requests from "../../Services/httpService";
import { toast } from "react-toastify";
import { getTotalAmount } from "../../Redux/actions/cartServices";

/* --------------> All about Swipper.js <--------------- */
/* ----> Import Swiper React components <----- */
import { Swiper, SwiperSlide } from "swiper/react";

/* -----> import required modules <------ */
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Zoom,
  Autoplay,
} from "swiper/modules";

/* -----> Import Swiper styles <----- */
import "swiper/css";
// import 'swiper/css/zoom';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { useNavigate } from "react-router";

const CheckoutCarts = ({
  cartData,
  setCartData,
  totalCartVal,
  setTotalCartVal,
  shippingType,
  couponVal,
  setCouponVal,
  subTotalVal,
  setSubTotalVal,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [shippingCharges, setShippingCharges] = useState(199);
  const [couponData, setCouponData] = useState([]);
  const [couponDataInput, setCoupanDataInput] = useState([]);
  // console.log(totalCartVal)
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.token) {
      const localCarts = JSON.parse(localStorage.getItem("carts"));
      if (!localCarts || !localCarts?.length) {
        navigate("/collections/all");
      }

      let totalCartAmount = getTotalAmount(localCarts);

      let shippingCharge = totalCartAmount > 999 ? 0 : 199;
      setShippingCharges(shippingCharge);
      setCartData(localCarts);
      setSubTotalVal(totalCartAmount);
      setTotalCartVal(totalCartAmount + shippingCharges);
      return;
    }

    const fetchCarts = async () => {
      const res = await requests.get("/cart");

      if (!res?.carts?.length) {
        navigate("/collections/all");
      }

      let totalCartAmount = getTotalAmount(res?.carts);
      let shippingCharge = totalCartAmount > 999 ? 0 : 199;
      setShippingCharges(shippingCharge);
      setCartData(res.carts);
      setSubTotalVal(totalCartAmount);
      setTotalCartVal(totalCartAmount + shippingCharge - couponVal);
    };

    setTimeout(fetchCarts, 2000);

    fetchCarts();
  }, [user?.token]);

  useEffect(() => {
    if (!cartData?.length) {
      return;
    }

    let totalCartAmount = getTotalAmount(cartData);

    if (shippingType === "standard") {
      let shippingCharge = totalCartAmount > 999 ? 0 : 199;
      setShippingCharges(shippingCharge);
      setTotalCartVal(totalCartAmount + shippingCharge - couponVal);
    } else {
      setShippingCharges(299);
      setTotalCartVal(totalCartAmount + 299 - couponVal);
    }
  }, [shippingType, cartData, couponVal, setTotalCartVal]);

  const handleDiscount = (e, coupanData, i) => {
    let couponCode = null;
    setCoupanDataInput(coupanData);
    if (!coupanData) {
      couponCode = e.target?.coupon?.value;

      if (!couponCode.length) {
        toast.error("Please Enter Coupon Code !!");
        setTotalCartVal(subTotalVal + shippingCharges);
        setCouponVal(0);
        return;
      }
    }

    const fetchCoupon = async () => {
      let checkCoupon = coupanData;

      if (!coupanData) {
        const res = await requests.get("/coupon/show");

        checkCoupon = res?.find((eachCoupan) => {
          return (
            eachCoupan?.couponCode?.toLowerCase() === couponCode?.toLowerCase()
          );
        });

        if (!checkCoupon) {
          toast.error("Coupon Code is Not Vaild");
          setTotalCartVal(subTotalVal + shippingCharges);
          setCouponVal(0);
          return;
        }
      }

      let totalAmount = getTotalAmount(cartData);
      setTotalCartVal(totalAmount);
      setSubTotalVal(totalAmount);

      toast("Coupon Applied Successfully !!");

      if (checkCoupon?.discountType?.type === "percentage") {
        let percentageVal = Number(checkCoupon?.discountType?.value);
        let discountedPrice = Math.floor((totalAmount * percentageVal) / 100);

        if (discountedPrice > checkCoupon.minimumAmount) {
          let newTotal =
            Number(totalAmount - checkCoupon?.minimumAmount) + shippingCharges;
          setTotalCartVal(newTotal);
          setCouponVal(checkCoupon?.minimumAmount);
        } else {
          let newTotal =
            Number(totalAmount - discountedPrice) + shippingCharges;
          setCouponVal(discountedPrice);
          setTotalCartVal(newTotal);
        }
      } else {
        let fixedVal = checkCoupon?.discountType?.value;

        if (totalAmount <= fixedVal + 100) {
          toast.error(`Cart Price Should be More than Discounted Price`);
          return;
        }

        let newTotal = Number(totalAmount - fixedVal) + shippingCharges;
        setTotalCartVal(newTotal);
        setCouponVal(fixedVal);
      }
    };

    fetchCoupon();
  };

  useEffect(() => {
    (async () => {
      setCouponData(await requests.get(`/coupon/show`));
    })();
  }, []);

  if (!cartData || !couponData) {
    return;
  }

  const currentDate = new Date();

  const filteredCouponData = couponData.filter((coupon) => {
    const endTime = new Date(coupon?.endTime);
    return endTime >= currentDate && totalCartVal >= coupon?.maximumAmount;
  });

  return (
    <>
      <div className="Checkout-right">
        <h2 className="text-white text-center">Order Summary</h2>
        <div className="Checkout-container">
          {cartData.length
            ? cartData.map((eachCart, idx) => {
                return (
                  <div className="Product-view" key={eachCart._id || idx}>
                    <div className="checkout-proudct-img">
                      <div className="image-block">
                        <img src={eachCart.image} alt="" />
                      </div>
                      <span>{eachCart.quantity}</span>
                    </div>
                    <div className="checkout-proudct-info">
                      <h3> {eachCart.title} </h3>
                      {eachCart.attribute && <p> {eachCart?.attribute} </p>}
                      {eachCart?.subscription && (
                        <h4>{eachCart?.subscription}</h4>
                      )}
                      {/* {eachCart?.subscription && <span>
                                        {eachCart?.price + " " + eachCart?.subscription}
                                      </span>} */}
                    </div>
                    <div className="col-md-2 checkout-proudct-price">
                      <p>
                        <span>&#8377; </span>
                        {eachCart.price}{" "}
                      </p>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <section className="checkout-footer-section">
          {/* <div className="hr-black-checkout"></div> */}

          <div className="checkout-total-amount-info">
            {/* <form
                            className="checkout-discount-code"
                            onSubmit={(e) => handleDiscount(e)}
                        >
                            <input
                                type="text"
                                name="couponSub"
                                placeholder="Discount Code"
                                value={couponDataInput}
                            />
                            <button>{couponDataInput?.length > 0 ? "Applied" : "Apply"} </button>
                        </form> */}

            {/* ---------->Offer Section <------------- */}
            <hr />
            {
              // totalCartVal >= 999 ?

              couponDataInput.length >= 0 ? (
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  centeredSlides={true}
                  scrollbar={{ draggable: true }}
                  pagination={{
                    type: "progressbar",
                  }}
                  // pagination={{ dynamicBullets: true, clickable: true }}
                  cssMode={true}
                  navigation={true}
                  mousewheel={true}
                  keyboard={true}
                  modules={[
                    Pagination,
                    Navigation,
                    Pagination,
                    Mousewheel,
                    Keyboard,
                    Autoplay,
                  ]}
                  autoplay={{
                    delay: 4500,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  // loop
                  className="checkout-offer-deal-section-swiper"
                >
                  {filteredCouponData?.map((coupon, i) => {
                    if (totalCartVal >= coupon?.maximumAmount) {
                      return (
                        <SwiperSlide
                          key={coupon?._id}
                          className="deal-and-offer-section-slides"
                        >
                          <div className="deal-and-offer-center-div">
                            <div>
                              <img src={coupon?.logo} alt="off1" />
                            </div>
                            <div className="offer-description-and-code">
                              <h3 className="description">{`${coupon?.discountType?.value} %`}</h3>
                              <div className="discount-code-and-offers-div">
                                <p className="discount-code">
                                  {coupon.couponCode}
                                </p>
                                <p className="discount-date">{`End At: ${new Date(
                                  coupon.endTime
                                ).toLocaleString()}`}</p>
                                <div className="swiper-to-more-offers">
                                  <p className="discount-date">
                                    Swipe to see more offers !!
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="deal-and-offer-apply-btn">
                              <button
                                onClick={(e) =>
                                  handleDiscount(e.target.value, coupon, i)
                                }
                              >
                                {"Apply"}
                              </button>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })}
                </Swiper>
              ) : (
                <div className="deal-and-offer-center-div-container">
                  <div className="deal-and-offer-center-div">
                    <div>
                      <img src={couponDataInput?.logo} alt="off1" />
                    </div>
                    <div className="offer-description-and-code">
                      <div>
                        <p className="discount-code">
                          {couponDataInput.couponCode.toUpperCase()}
                        </p>
                      </div>
                      <div>
                        <p className="discount-code-1">Applied</p>
                      </div>
                      <h3 className="description">{`${couponDataInput?.discountType?.value} %`}</h3>
                    </div>
                  </div>
                </div>
              )

              // :

              // null
            }

            <div className="checkout-offer-section">
              <div className="checkout-subtotal">
                <div className="checkout-subtotal-text">
                  <h2>Subtotal</h2>
                </div>
                <div className="checkout-subtotal-price">
                  <span>&#8377;</span> {subTotalVal}
                </div>
              </div>
              <div className="checkout-estimated-shipping">
                <div className="checkout-subtotal-text">
                  <h2>Estimated Shipping ({shippingType})</h2>
                </div>
                <div className="checkout-subtotal-price">
                  {shippingCharges ? <><span>+ &#8377;</span> {shippingCharges}</> : <><span> FREE </span></>}
                </div>
              </div>
              {couponVal ? (
                <div className="checkout-estimated-shipping">
                  <div className="checkout-subtotal-text">
                    <h2>Discount Coupon</h2>
                  </div>
                  <div className="checkout-subtotal-price">
                    <span>- &#8377;</span> {couponVal}
                  </div>
                </div>
              ) : null}
              <div className="checkout-total">
                <div className="checkout-subtotal-text">
                  <h2>Total</h2>
                </div>
                <div className="checkout-subtotal-price">
                  <span>&#8377;</span> {totalCartVal}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="checkout-checkout-btn">
                    <button\>Place Order</button>
                </div> */}
        </section>
      </div>
    </>
  );
};

export default CheckoutCarts;
