import React, { useEffect, useState } from "react";
import "../Accounts/Ollys-Login/Login.css";
import "./Shop.css";
import { BsFillStarFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import NewMobileProduct from "./NewMobileProduct";
import useWindowInnerWidth from "../hooks/useWindowInnerWidth";
import {
    fetchProductPrice,
    findProductAttribute,
} from "../../Redux/actions/productService";
import requests from "../../Services/httpService";


/* --------------> All about Swipper.js <--------------- */
/* ----> Import Swiper React components <----- */
import { Swiper, SwiperSlide } from "swiper/react";

/* -----> import required modules <------ */
import { Navigation, Pagination, Mousewheel, Keyboard, Zoom } from 'swiper/modules';

/* -----> Import Swiper styles <----- */
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const ProductReviews = ({ productId, styles }) => {
    const [reviews, setReviews] = useState({});

    useEffect(() => {
        const fetchReviews = async () => {
            const res = await requests.get(`/reviews/by-product/${productId}`);
            setReviews(res);
        };

        fetchReviews();
    }, [productId]);

    return (
        <div style={styles?.color} className="product-review-data-info">
            <span style={styles?.color}>{reviews?.totalReviews} reviews</span>
        </div>
    );
};

const ShopProductImage = ({ product }) => {
    const [viewProduct, setViewProduct] = useState(false);

    return (
        // <div className="ShopProducts-img">
        //     <img
        //         src={product?.image[0]}
        //         onMouseOut={(e) => {
        //             e.currentTarget.src = product?.image[0];
        //             setViewProduct(false);
        //         }}
        //         onMouseOver={(e) => {
        //             e.currentTarget.src = product?.image[1];
        //             setViewProduct(true);
        //         }}
        //         alt=""
        //     />
        //     {viewProduct && (
        //         <Link
        //             className="hover-image-view-product-btn"
        //             to={`/products/${product?.slug}`}
        //         >
        //             View Product
        //         </Link>
        //     )}
        // </div>

        <Link className="ShopProducts-img" to={`/products/${product?.slug}`}>
            <img
                src={product?.image[0]}
                onMouseOut={(e) => {
                    e.currentTarget.src = product?.image[0];
                    setViewProduct(false);
                }}
                onMouseOver={(e) => {
                    e.currentTarget.src = product?.image[1];
                    setViewProduct(true);
                }}
                alt=""
            />
        </Link>

    );
};

export const Products = ({ categoryId, variants, styles }) => {
    const [products, setProducts] = useState([]);
    const showValue = useWindowInnerWidth();

    const fetchProductByCategoryId = async (id) => {
        try {
            const res = await requests.get(`/products/category/${id}`);

            if (res?.data) {
                setProducts(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchProductByCategoryId(categoryId);
    }, [categoryId]);

    return (
        <>
            <Swiper
                // slidesPerView={4}
                // spaceBetween={20}
                // loop={true}
                // direction={'vertical'}
                scrollbar={{ draggable: true }}
                pagination={{
                    // type: 'progressbar',
                    dynamicBullets: true,
                    clickable: true
                }}
                cssMode={true}
                navigation={true}
                mousewheel={true}
                keyboard={true}
                modules={[Pagination, Navigation, Pagination, Mousewheel, Keyboard]}
                className="productSwipper"

                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
            >
                <div className="ShopProducts-section" style={styles?.fullBg}>
                    {products.length
                        ? products.map((product) => {
                            return (
                                <SwiperSlide
                                    key={product._id}
                                    className="products-swapper-slides"
                                >
                                    <div
                                        className="ShopProducts-content wow animate__animated animate__fadeInUp"
                                        key={product._id}
                                    >
                                        <ShopProductImage product={product} />
                                        <div
                                            className="shop-product-info"
                                            style={styles?.color}
                                        >
                                            <h3 style={styles?.color}>
                                                {product?.title?.en}
                                            </h3>
                                            <div className="price-and-size-info">
                                                <p
                                                    className="ShopProducts-size"
                                                    style={styles?.color}
                                                >
                                                    {findProductAttribute(
                                                        product,
                                                        variants
                                                    )}
                                                </p>
                                                <h4 style={styles?.color}>
                                                    &#8377;
                                                    {fetchProductPrice(product)}
                                                </h4>
                                            </div>

                                            <Link
                                                style={styles?.color}
                                                to={`/products/${product?.slug}`}
                                            >
                                                View Product
                                            </Link>
                                            <div className="ShopProduct-star-review">
                                                <div className="stars">
                                                    <span>
                                                        <BsFillStarFill />
                                                    </span>
                                                    <span>
                                                        <BsFillStarFill />
                                                    </span>
                                                    <span>
                                                        <BsFillStarFill />
                                                    </span>
                                                    <span>
                                                        <BsFillStarFill />
                                                    </span>
                                                    <span>
                                                        <BsFillStarFill />
                                                    </span>
                                                </div>
                                                <ProductReviews
                                                    productId={product._id}
                                                    styles={styles}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })
                        : null}
                </div>
            </Swiper>
        </>
    );
};
