import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FaFileDownload } from 'react-icons/fa';
import { RiDownload2Fill } from "react-icons/ri";
import { MdLocalPrintshop } from 'react-icons/md';
import { GrClose } from 'react-icons/gr';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/go-gourment-logo.png';
import './Invoice.css';
import { useNavigate } from 'react-router';
import requests from '../../Services/httpService';

export const Invoice = ({ showModal, fullscreen, allOrderData }) => {
  const [show, setShow] = useState(showModal);
  const [invoiceGlobalData, setInvoiceGlobalData] = useState([]);

  const { address, company_name, shop_name, contact, default_currency, email, post_code, vat_number, website } = invoiceGlobalData;
  const { _id, invoice, createdAt, paymentMethod, shippingCost, status, subTotal, total, discount, user, user_info, cart } = allOrderData[0];

  const navigate = useNavigate()

  const handleClose = () => {
    setShow(false);
    navigate("/accounts/order-history")
  }

  useEffect(() => {
    (async () => {
      setInvoiceGlobalData(await requests.get(`/setting/global/all`))
    })()
  }, []);

  const totalQty = cart?.reduce((prevTotalQty, item) => prevTotalQty + item?.quantity, 0);



  const date = new Date(createdAt);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;


  const doc = new jsPDF();
  const maxWidth = 65;

  const positioningJspdf = () => {
    doc.setFontSize(12);

    doc.addImage(logo, 'PNG', 13, 17, 42, 12);
    doc.text('TAX INVOICE', 168, 26);
    doc.text('______________________________________________________________________________', 12, 32);

    /* -----------------> Add header content  <-------------*/
    doc.text(`Order Id : ${_id}`, 15, 45);
    doc.text(`Order Date : ${formattedDate}`, 15, 55, { maxWidth });
    doc.text(`Invoice No : #${invoice}`, 140, 45);


    /* -----------------> Add shipping address  <-------------*/
    doc.text('Shipping Address', 15, 70);
    doc.text(`${user_info?.name}`, 15, 80);
    doc.text(`${user_info?.city} - ${user_info?.pincode}\n${user_info?.country}\nContact No. : ${user_info?.contact}`, 15, 87);


    /* -----------------> Add billing address  <-------------*/
    doc.text('Billing Address', 140, 70);
    doc.text(`${user_info?.name}`, 140, 80);
    doc.text(`${user_info?.city} - ${user_info?.pincode}\n${user_info?.country}\nContact No. : ${user_info?.contact}`, 140, 87);


    /* -----------------> Add payment details  <-------------*/
    const columns = ['SR.', 'PRODUCTS', 'SUBSCRITION DETAILS', 'ATTRIBUTES', 'QUANTITY', 'ITEM PRICE', 'AMOUNT'];
    const dataList = cart?.map((item, i) => {
      return [`${(i + 1)}`, `${item?.title}`, `${item?.subscription ?? "One Time Purchase"}`, `${item?.attribute}`, ` ${item?.quantity} `, `${item?.price}`, `${Number(item?.quantity) * Number(item?.price)}`]
    })
    const data = dataList;

    doc.autoTable({
      startY: 110,
      theme: "grid",
      head: [columns],
      body: data,
      margin: { left: 10, right: 10, top: 20, bottom: 30 },
      styles: { halign: 'center' },
      headStyles: { fillColor: [76, 66, 148], fontStyle: 'bold' },
      alternateRowStyles: { fillColor: [224, 231, 255] },
      tableLineColor: [76, 66, 148],
      tableLineWidth: 0.2,
      bodyStyles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        fontFamily: "roc-grotesk-stretch-600",
      }
    });

    const columns2 = ['PAYMENT METHOD', 'TOTAL QTY', 'SHIPPING COST', 'DISCOUNT', 'TOTAL AMOUNT'];

    const data2 = [
      [`${paymentMethod} `, `${totalQty} `, `${shippingCost} `, `${discount} `, `${total} `],
    ];


    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: [columns2],
      body: data2,
      margin: { left: 10, right: 10, top: 20, bottom: 30 },
      styles: { halign: 'center' },
      headStyles: { fillColor: [76, 66, 148], fontStyle: 'bold' },
      tableLineColor: [76, 66, 148],
      tableLineWidth: 0.2,
      bodyStyles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        fontFamily: "roc-grotesk-stretch-600",
        fontStyle: 'bold'
      }
    });

    /* -----------------> Add table footer  <-------------*/
    doc.text('All Values are in INR.', 160, doc.autoTable.previous.finalY + 8);

    /* -----------------> Add seller address  <-------------*/
    doc.text(`Seller Registered Address `, 10, doc.autoTable.previous.finalY + 20);
    doc.text(`${shop_name} \n${address} \nPin Code - ${post_code}, `, 10, doc.autoTable.previous.finalY + 28);
    doc.text(`Email: ${email} \nContact No. : ${contact} \nVisit Our Shop Website: ${website} \nVAT No. : ${vat_number} `, 10, doc.autoTable.previous.finalY + 45);

    /* -----------------> Add e-sign  <-------------*/
    doc.text(`${"Signature"} `, 167, doc.autoTable.previous.finalY + 45);

    /* -----------------> Add Delaration  <-------------*/
    doc.text(`Delaration, `, 10, doc.autoTable.previous.finalY + 75);
    doc.text(`${"The goods sold are intended for end user consumption and not for resale."} `, 10, doc.autoTable.previous.finalY + 83);

  }

  /* -----------------> To Download Pdf  <-------------*/
  const generatePdf = () => {
    positioningJspdf();
    doc.save('invoice.pdf');
  }

  /* -----------------> To Print Invoice  <-------------*/
  const printInvoice = () => {
    positioningJspdf();

    /* -----------------> Save the PDF  <-------------*/
    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');
  }


  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose} className='invoice-modal'>
      <Modal.Header >
        <Modal.Title>Invoice</Modal.Title>
        <img src={logo} alt="logo" />
        <GrClose onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="Invoice-container">
          <div className="InvoiceHeader-section">
            <div className='order-details-download-print-wapper'>
              <div className="Order-details">
                <p className=''>Order Id: {_id}</p>
                <p className=''>{`Invoice No: ${invoice} `}</p>
                <p>Order Date: {formattedDate}</p>
              </div>
            </div>
            <div className='go-gourment-logo'>
              <h3 className='Taxtext'>Tax Invoice</h3>
              <div className="IHeaderButton">
                <div className='download-btn-wapper'>
                  <button className='invoice-print-btn' onClick={printInvoice}><MdLocalPrintshop /></button>
                  <button className='invoice-download-btn' onClick={generatePdf}>< RiDownload2Fill /> </button> <span />
                </div>
              </div>
              {/* <img src={logo} alt="go-gourment-shop-logo" /> */}
            </div>
          </div>
          <hr className='border-2' />
          <div className="InvoiceMain ">
            <div className="AddressDetails">
              <div className="ShippingAddr ">
                <h6 className='fw-bold'>Shipping Address</h6>
                <p>{user_info?.name}</p>
                <p>{user_info?.city} - {user_info?.pincode}<br />{user_info?.country}<br />{user_info?.email}<br />{user_info?.contact}</p>
              </div>
              <div className="Billing Addr">
                <h6 className='fw-bold'>Billing Address</h6>
                <p>{user_info?.city} - {user_info?.pincode}<br />{user_info?.country}<br />{user_info?.email}<br />{user_info?.contact}</p>
              </div>
            </div>

            <div className="PaymentDetails">
              <Table responsive className='invoice-table'>
                <thead className='invoice-table-head'>
                  <tr>
                    <th>SR.</th>
                    <th>PRODUCTS</th>
                    <th>SUBSCRITION DETAILS</th>
                    <th>ATTRIBUTES</th>
                    <th>QUANTITY</th>
                    <th>ITEM PRICE</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody className='invoice-table-body'>
                  {cart?.map((item, i) => {
                    return (
                      <tr>
                        <td>{`${i + 1}.`}</td>
                        <td>{`${item?.title} `}</td>
                        <td>{`${item?.subscription ?? "One Time Purchase"} `}</td>
                        <td>{`${item?.attribute} `}</td>
                        <td>{`${item?.quantity} `}</td>
                        <td>{`${item?.price} `}</td>
                        <td>{`${Number(item?.price) * Number(item?.quantity)} `}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>

              <Table responsive className='invoice-extra-table'>
                <thead className='invoice-table-head'>
                  <tr>
                    <th>PAYMENT METHOD </th>
                    <th>Total Qty</th>
                    <th>SHIPPING COST </th>
                    <th>DISCOUNT</th>
                    <th>TOTAL AMOUNT</th>
                  </tr>
                </thead>
                <tbody className='invoice-table-body'>
                  <tr>
                    <td>{`${paymentMethod} `}</td>
                    <td>{`${totalQty} `}</td>
                    <td>{`${shippingCost} `}</td>
                    <td>{discount}</td>
                    <td>{total}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className='all-value-in-inr'>
              <p>All values are in INR.</p>
            </div>
          </div>
          <div className="sellerAddress">
            <div className='seller-address-info'>
              <h6>Seller Registered Address</h6>
              <p>{shop_name ? shop_name : company_name}</p>
              <p>{`${address} Pincode - ${post_code} `}</p>
              <p>{`Contact: ${contact} `}</p>
              <p>{`Email: ${email}`}</p>
              <p>{`VAT No. : ${vat_number} `}</p>
              <p>{`Visit Our Shop website: ${website} `}</p>
            </div>
            <div className='invoice-decleration'>
              <h6>Declaration</h6>
              <p>The goods sold are intended for end user consumption and not for resale.</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}



