import React, { useEffect, useState } from "react";
import "./Checkout.css";
import "../Shop/ViewProducts/ViewProducts.css";
import { IoAdd } from "react-icons/io5";
import CheckoutCarts from "./CheckoutCarts";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { generateEncryptedUserId } from "../../Redux/actions/cartServices";
import requests from "../../Services/httpService";
import { GoogleLogin } from "@react-oauth/google";
import { registerUserByGoogle } from "../../Redux/actions/authServices";
import { useDispatch, useSelector } from "react-redux";

const Checkout = () => {
  const [showInput, setShowInput] = useState(false);
  const [shippingType, setShippingType] = useState("standard");
  const [cartData, setCartData] = useState([]);
  const [totalCartVal, setTotalCartVal] = useState(0);
  const [subTotalVal, setSubTotalVal] = useState(0);
  const [couponVal, setCouponVal] = useState(0);

  const checkUser = useSelector((store) => store?.auth?.user);

  const dispatch = useDispatch();

  const [showMobile, setShowMobile] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const Navigate = useNavigate();

  useEffect(() => {
    const localAddress = JSON.parse(localStorage.getItem("delivery-address"));
    if (localAddress) {
      setDeliveryAddress(localAddress);
      if (localAddress?.address1) setShowInput(true);
      if (localAddress?.mobile) setShowMobile(true);
    }
  }, []);

  const handleHiddenInput = (event) => {
    event.preventDefault();
    setShowInput(true);
  };

  const isValidData = (data) => {
    for (let objData in data) {
      if (typeof data[objData] == "string" && data[objData].length === 0) {
        return false;
      }
    }
    return true;
  };

  const handleCheckout = async (e) => {
    try {
      e.preventDefault();

      let checkoutData = {
        firstname: e.target?.firstname?.value,
        lastname: e.target?.lastname?.value,
        email: e.target?.email?.value,
        country: e.target?.country?.value,
        state: e.target?.state?.value,
        address: e.target?.address?.value,
        city: e.target?.city?.value,
        pincode: e.target?.pincode?.value,
        phone: e.target?.phone?.value,
        shipping: shippingType,
      };

      if (!isValidData(checkoutData)) {
        toast.error("Please Fill All Fields");
        return;
      }

      checkoutData["address1"] = e.target?.apartment?.value;
      checkoutData["mobile"] = e.target?.mobile?.value;

      const localCarts = JSON.parse(localStorage.getItem("carts"));
      localStorage.setItem("delivery-address", JSON.stringify(checkoutData));

      if (localCarts) {
        toast.error("Please Login First to Continue Purchasing !!");
        Navigate("/accounts/login");
        return;
      }

      const user = JSON.parse(localStorage.getItem("user"));

      const ccAvenueData = {
        merchant_id: 3166778,
        order_id: user._id,
        currency: "INR",
        amount: totalCartVal,
        redirect_url: "https://gogourmet.shop/api/v1/ccavResponseHandler",
        cancel_url: "https://gogourmet.shop/api/v1/ccavResponseHandler",
        language: "EN",
        billing_name: checkoutData.firstname + " " + checkoutData.lastname,
        billing_address: checkoutData.address,
        billing_city: checkoutData.city,
        billing_state: checkoutData.address,
        billing_zip: checkoutData.pincode,
        billing_country: checkoutData.country,
        billing_tel: checkoutData.phone,
        billing_email: checkoutData.email,
      };

      const uniqueUserId = generateEncryptedUserId();

      await requests.put(`/customer/add/unique/${user._id}`, {
        uniqueId: uniqueUserId,
      });

      document.getElementById("merchant_id").value = ccAvenueData.merchant_id;
      document.getElementById("order_id").value = ccAvenueData.order_id;
      document.getElementById("amount").value = ccAvenueData.amount;
      document.getElementById("redirect_url").value = ccAvenueData.redirect_url;
      document.getElementById("cancel_url").value = ccAvenueData.cancel_url;
      document.getElementById("language").value = ccAvenueData.language;
      document.getElementById("billing_name").value = ccAvenueData.billing_name;
      document.getElementById("billing_address").value =
        ccAvenueData.billing_address;
      document.getElementById("billing_city").value = ccAvenueData.billing_city;
      document.getElementById("billing_state").value =
        ccAvenueData.billing_state;
      document.getElementById("billing_zip").value = ccAvenueData.billing_zip;
      document.getElementById("billing_country").value =
        ccAvenueData.billing_country;
      document.getElementById("billing_tel").value = ccAvenueData.billing_tel;
      document.getElementById("billing_email").value =
        ccAvenueData.billing_email;
      document.getElementById("merchant_param1").value = subTotalVal;
      document.getElementById("merchant_param2").value =
        shippingType === "standard" ? 299 : 399;
      document.getElementById("merchant_param3").value = couponVal;
      document.getElementById("merchant_param4").value = uniqueUserId;
      document.getElementById("customer_identifier").value = uniqueUserId;

      document.getElementById("ccAvenueForm").submit();
    } catch (error) {
      toast.error("Internal Error Occurs !!");
    }
  };

  const onGoogleSuccess = (data) => {
    dispatch(registerUserByGoogle(data.credential));
  };

  // console.log(cartData);

  return (
    <>
      <div className="Checkout-Section">
        <div className="Main-section row container">
          <div className="Checkout-left">
            <div className="checkout-hr-line"></div>
            <div className="Checkout-form">
              <form
                onSubmit={handleCheckout}
                className="checkout-form-container"
              >
                {checkUser ? <div className="contact-section">
                  <h3>Conatct</h3>
                  <div className="">
                    <label
                      htmlFor="email"
                      className="checkout-label-text-background"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      defaultValue={
                        checkUser?.email || deliveryAddress?.email && deliveryAddress.email
                      }
                      className="form-control Checkout-input"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-checkbox">
                    <input
                      type="checkbox"
                      className="checkedBox"
                      id="checkbox-slhgdks"
                    />
                    <span>Email me with news and offers</span>
                  </div>
                </div> : <div className="googleSignUp">
                  <GoogleLogin
                    onSuccess={onGoogleSuccess}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    theme="filled_black"
                  />
                </div>}
                <div className="delivery-section">
                  <h3>Delivery</h3>
                  <div className="row justify-content-between">
                    <div className="col-5 ">
                      <div className="">
                        <label
                          htmlFor="firstname"
                          className="checkout-label-text-background"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstname"
                          name="firstname"
                          className="form-control Checkout-input"
                          placeholder="First Name"
                          defaultValue={
                            checkUser?.name.split(" ")[0] || deliveryAddress?.firstname
                          }
                          aria-label="First name"
                        />
                      </div>
                    </div>
                    <div className="col-5 ">
                      <div className="">
                        <label
                          htmlFor="lastname"
                          className="checkout-label-text-background"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="lastname"
                          id="lastname"
                          className="form-control Checkout-input"
                          placeholder="Last Name"
                          defaultValue={
                            checkUser?.name.split(" ")[1] || deliveryAddress?.lastname &&
                            deliveryAddress.lastname
                          }
                          aria-label="Last name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="country"
                      className="checkout-label-text-background"
                    >
                      Country/region
                    </label>
                    <select
                      name="country"
                      id="country"
                      className="form-select Checkout-input"
                    >
                      <option value="India">India</option>
                    </select>
                  </div>
                  <div className="">
                    <label
                      htmlFor="state"
                      className="checkout-label-text-background"
                    >
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      className="form-control Checkout-input"
                      id="state"
                      defaultValue={
                        deliveryAddress?.state && deliveryAddress.state
                      }
                      aria-label="state"
                      placeholder="State"
                    />
                  </div>
                  <div className="input-group">
                    <div className="input-address">
                      <label
                        htmlFor="address"
                        className="checkout-label-text-background"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        className="form-control Checkout-input "
                        defaultValue={
                          deliveryAddress?.address && deliveryAddress.address
                        }
                        placeholder="Address"
                      />
                    </div>
                  </div>
                  <div className={showInput ? "d-none" : "my-2 "}>
                    <Link
                      to="#"
                      onClick={handleHiddenInput}
                      className="text-decoration-none"
                    >
                      <IoAdd /> Add apartment, suite, etc.
                    </Link>
                  </div>
                  <div className={showInput ? "d-block my-3 " : "d-none"}>
                    <label
                      htmlFor="apartment"
                      className="checkout-label-text-background"
                    >
                      Apartment
                    </label>
                    <input
                      type="text"
                      id="apartment"
                      name="apartment"
                      className="form-control Checkout-input"
                      defaultValue={
                        deliveryAddress?.address1 && deliveryAddress.address1
                      }
                      placeholder="Apartment, suite, etc."
                    />
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-5">
                      <div className="">
                        <label
                          htmlFor="city"
                          className="checkout-label-text-background"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          className="form-control Checkout-input"
                          defaultValue={
                            deliveryAddress?.city && deliveryAddress.city
                          }
                          placeholder="City"
                          aria-label="City"
                        />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="">
                        <label
                          htmlFor="pincode"
                          className="checkout-label-text-background"
                        >
                          Pincode
                        </label>
                        <input
                          type="text"
                          id="pincode"
                          name="pincode"
                          className="form-control Checkout-input"
                          defaultValue={
                            deliveryAddress?.pincode && deliveryAddress.pincode
                          }
                          placeholder="Pincode"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="">
                      <label
                        htmlFor="phone"
                        className="checkout-label-text-background"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control Checkout-input"
                        defaultValue={
                          deliveryAddress?.phone && deliveryAddress.phone
                        }
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div className="form-checkbox">
                    <input
                      type="checkbox"
                      checked={showMobile}
                      onChange={() => setShowMobile(!showMobile)}
                      className="checkedBox"
                      id="checkboxOffer"
                    />
                    <label htmlFor="checkboxOffer">Text me with news and offers</label>
                    
                  </div>
                  {showMobile ? (
                    <div className="">
                        <label
                          htmlFor="mobile"
                          className="checkout-label-text-background"
                        >
                          Mobile number
                        </label>
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          defaultValue={
                            deliveryAddress?.mobile && deliveryAddress.mobile
                          }
                          className="form-control Checkout-input w-full"
                          placeholder="Mobile number"
                        />
                      {/* {5 > 3 ? (
                        <span className="input-group-text searchIcon border border-start-0">
                          <select name="" className="searchIcon border-0" id="">
                            <option value="IN">IN</option>
                          </select>
                        </span>
                      ) : (
                        ""
                      )} */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* Shipping */}
                <div className="Shipping-section">
                  <h3>Shipping method</h3>
                  {true ? (
                    <div className="shipping-container">
                      <div className="shipping-box p-3 rounded-top border border-1 d-flex justify-content-between">
                        <div className="d-flex justify-content-between ">
                          <input
                            type="radio"
                            value="standard"
                            className="px-1"
                            checked={shippingType === "standard"}
                            onChange={(e) => setShippingType(e.target.value)}
                          />
                          <div>
                            <span />{" "}
                            <span className="px-2">
                              Standard Delivery (2-3 Working Days)
                            </span>
                          </div>
                        </div>
                        <div className="checkout-delivery-priciing">
                          &#8377; 199
                        </div>
                      </div>
                      <div className="shipping-box p-3 border border-1 rounded-bottom d-flex justify-content-between">
                        <div className="d-flex justify-content-between ">
                          <input
                            type="radio"
                            onChange={(e) => setShippingType(e.target.value)}
                            value="express"
                            checked={shippingType === "express"}
                          />
                          <div>
                            <span />{" "}
                            <span className="px-2">
                              Express Delivery (Next Working Day)
                            </span>
                          </div>
                        </div>
                        <div className="checkout-delivery-priciing">
                          &#8377; 299
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Shipping-message alert border-2">
                      Enter your shipping address to view available shipping
                      methods.
                    </div>
                  )}
                </div>
                {/* <div className="Payment-section">
                  <h3>Payment</h3>
                  <p>All transactions are secure and encrypted.</p>
                  <div className="Payment-card p-3 mt-4 searchIcon border border-1 rounded-top d-flex justify-content-between">
                    <div className='d-flex justify-content-between '>
                      <input type="radio" value="creditcard" defaultChecked />
                      <div><span /> <span className='px-2'>Credit Card</span></div>
                    </div>
                  </div>
                </div>
                <div className="card-box">
                  <div className='input-group my-3'>
                    <div className="">
                      <input type="text"  name='cardno' className='form-control Checkout-input border-end-0' id='cardno' aria-label='address' placeholder='Card number' />
                      <label htmlFor="cardno" className='label-form' >Card number</label>
                    </div>
                    <span className='input-group-text searchIcon '><GoLock /></span>
                  </div>
                  <div className="row justify-content-between my-3">
                    <div className="col-5 ">
                      <div className="">
                        <input type="text" id='expirydate'  name='expirydate' className="form-control Checkout-input" placeholder="Expiration Date (MM/YY)" aria-label="expirydate" />
                        <label htmlFor="expirydate" className='label-form' >Expiration Date (MM/YY)</label>
                      </div>
                    </div>
                    <div className="col-5 ">
                      <div className="">
                        <input type="text" name='securitycode' id='securitycode' className="form-control Checkout-input" placeholder="Security code"  aria-label="Security code" />
                        <label htmlFor="securitycode" className='label-form' >Security code</label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <button className="notifymebtn">Pay now</button>
              </form>
            </div>
          </div>
          <CheckoutCarts
            cartData={cartData}
            setCartData={setCartData}
            totalCartVal={totalCartVal}
            setTotalCartVal={setTotalCartVal}
            shippingType={shippingType}
            couponVal={couponVal}
            setCouponVal={setCouponVal}
            subTotalVal={subTotalVal}
            setSubTotalVal={setSubTotalVal}
          />
        </div>
      </div>
      <form
        id="ccAvenueForm"
        style={{
          visibility: "hidden",
          display: "none",
        }}
        method="POST"
        name="customerData"
        action="https://gogourmet.shop/api/v1/ccavRequestHandler "
      >
        <table width="40%" height="100" border="1" align="center">
          <tr>
            <td>Merchant Id</td>
            <td>
              <input type="text" name="merchant_id" id="merchant_id" />
            </td>
          </tr>
          <tr>
            <td>Order Id</td>
            <td>
              <input type="text" name="order_id" id="order_id" />
            </td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>
              <input type="text" name="currency" value="INR" />
            </td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>
              <input type="text" name="amount" id="amount" />
            </td>
          </tr>
          <tr>
            <td>Redirect URL</td>
            <td>
              <input type="text" name="redirect_url" id="redirect_url" />
            </td>
          </tr>
          <tr>
            <td>Cancel URL</td>
            <td>
              <input type="text" name="cancel_url" id="cancel_url" />
            </td>
          </tr>
          <tr>
            <td>Language</td>
            <td>
              <input type="text" name="language" id="language" value="EN" />
            </td>
          </tr>
          <tr>
            <td>Billing Name</td>
            <td>
              <input type="text" name="billing_name" id="billing_name" />
            </td>
          </tr>
          <tr>
            <td>Billing Address:</td>
            <td>
              <input type="text" name="billing_address" id="billing_address" />
            </td>
          </tr>
          <tr>
            <td>Billing City:</td>
            <td>
              <input type="text" name="billing_city" id="billing_city" />
            </td>
          </tr>
          <tr>
            <td>Billing State:</td>
            <td>
              <input type="text" name="billing_state" id="billing_state" />
            </td>
          </tr>
          <tr>
            <td>Billing Zip:</td>
            <td>
              <input type="text" name="billing_zip" id="billing_zip" />
            </td>
          </tr>
          <tr>
            <td>Billing Country:</td>
            <td>
              <input type="text" name="billing_country" id="billing_country" />
            </td>
          </tr>
          <tr>
            <td>Billing Tel:</td>
            <td>
              <input type="text" name="billing_tel" id="billing_tel" />
            </td>
          </tr>
          <tr>
            <td>Billing Email:</td>
            <td>
              <input type="text" name="billing_email" id="billing_email" />
            </td>
          </tr>
          <tr>
            <td>Customer Id:</td>
            <td>
              <input
                type="text"
                name="customer_identifier"
                id="customer_identifier"
              />
            </td>
          </tr>
          <tr>
            <td>Merchant Param1</td>
            <td>
              <input type="text" name="merchant_param1" id="merchant_param1" />
            </td>
          </tr>
          <tr>
            <td>Merchant Param2</td>
            <td>
              <input type="text" name="merchant_param2" id="merchant_param2" />
            </td>
          </tr>
          <tr>
            <td>Merchant Param3</td>
            <td>
              <input type="text" name="merchant_param3" id="merchant_param3" />
            </td>
          </tr>
          <tr>
            <td>Merchant Param4</td>
            <td>
              <input type="text" name="merchant_param4" id="merchant_param4" />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <input type="submit" value="Checkout" />
            </td>
          </tr>
        </table>
      </form>
    </>
  );
};

export default Checkout;
