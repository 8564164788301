import React, { useEffect, useRef, useState } from "react";
// import { Navigation2 } from "../Navigation/Navigation2";
import "../Accounts/Ollys-Login/Login.css";
import "./Shop.css";
import { Products } from "./Products";
// import ChocoPretzelBanner from './images/'
import { ShopBanner } from "./ShopBanner";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import requests from "../../Services/httpService";
import { RiArrowDropDownLine } from "react-icons/ri";
import { getStylesLan } from "../../utils/Constants";

const ShowCategory = ({ eachCategory, dynamicId, variants, styles }) => {
    const ref = useRef();
    const location = useLocation();

    useEffect(() => {
        if (location?.hash && ref.current.id === location.hash.slice(1)) {
            ref.current.scrollIntoView();
        }
    }, [location]);

    // console.log("styles={styles}---->", styles)

    return (
        <div
            className="pretzelThins"
            ref={ref}
            key={eachCategory._id}
            id={dynamicId.toLowerCase()}
            style={styles.fullBg}

        >
            <ShopBanner shopData={eachCategory} styles={styles} />
            <Products
                categoryId={eachCategory._id}
                variants={variants}
                styles={styles}
            />
        </div>
    );
    // {
    //     /* <div className='OlivesSection'>
    //   <ShopBanner ShopOlivesBanner={ShopOlivesBanner} />
    //   <Products ShopOlivesProducts={ShopOlivesProducts} />
    // </div>
    // <ShopBanner ShopChocoPretzelBanner={ShopChocoPretzelBanner} />
    // <Products ShopChocoPretzelProducts={ShopChocoPretzelProducts} /> */
    // }
    // {
    //     /* <ShopSnacksBundle />
    // <Merch />
    // <OneFeedsHome /> */
    // }
};

export const Shop = () => {
    const [showValue, setShowValue] = useState(window.innerWidth);
    const [dropdownOpen, setDropDownOpen] = useState(false);
    const [variants, setVariants] = useState([]);

    const shopData = useSelector((store) => store.storeSettings?.categories);

    const toggleOpen = () => {
        setDropDownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const handleWidth = () => {
            setShowValue(window.innerWidth);
        };
        window.addEventListener("resize", handleWidth);

        const fetchProductAttribute = async () => {
            const res = await requests.get("/attributes/show");
            setVariants(res[0].variants);
        };

        fetchProductAttribute();

        return () => {
            window.removeEventListener("resize", handleWidth);
        };
    }, []);

    if (!shopData) {
        return;
    }

    // console.log(
    //     "shop -------------->",
    //     shopData[0]?.name?.en && shopData[0]?.name?.en
    // );

    return (
        <div>
            <div className="shop-page-container">
                <h1 className="shop-page-heading text-center">Shop Now</h1>
                {/* <div className="Shopmenu-bar">
                    {showValue > 1000 ? (
                        <div className="barcontent">
                            <button
                                to
                                className="barbutton text-decoration-none"
                            >
                                {shopData[0]?.name?.en && shopData[0]?.name?.en}
                            </button>

                            {Array.isArray(shopData) &&
                                shopData[0].children &&
                                shopData[0].children.map((eachCategory) => {
                                    let dynamicLink = eachCategory.name?.en
                                        .trim()
                                        .split(" ")
                                        .join("-");
                                    return (
                                        <Link
                                            key={eachCategory._id}
                                            to={`#${dynamicLink.toLowerCase()}`}
                                            className="barbutton text-decoration-none"
                                        >
                                            {eachCategory?.name?.en}
                                        </Link>
                                    );
                                })}
                        </div>
                    ) 
                    : 
                    (
                        <div className="shop-page-Dropdown">
                            <div
                                className="shop-page-Dropdown-button-div"
                                onClick={toggleOpen}
                            >
                                <button type="button">
                                    {shopData[0]?.name?.en &&
                                        shopData[0]?.name?.en}
                                </button>
                                <RiArrowDropDownLine className="dropdown-arrow-icon" />
                            </div>
                            {dropdownOpen && (
                                <div className="shop-page-Dropmenubox">
                                    {Array.isArray(shopData) &&
                                        shopData[0].children &&
                                        shopData[0].children.map(
                                            (eachCategory) => {
                                                let dynamicLink =
                                                    eachCategory.name?.en
                                                        .trim()
                                                        .split(" ")
                                                        .join("-");
                                                return (
                                                    <Link
                                                        key={eachCategory._id}
                                                        onClick={toggleOpen}
                                                        to={`#${dynamicLink.toLowerCase()}`}
                                                        className="barbutton"
                                                    >
                                                        {eachCategory?.name?.en}
                                                    </Link>
                                                );
                                            }
                                        )}
                                </div>
                            )}
                        </div>
                    )
                    }
                </div> */}

                <div className="Shopmenu-bar">

                    <div className="barcontent">
                        <button
                            to
                            className="barbutton text-decoration-none"
                        >
                            {shopData[0]?.name?.en && shopData[0]?.name?.en}
                        </button>

                        {Array.isArray(shopData) &&
                            shopData[0].children &&
                            shopData[0].children.map((eachCategory) => {
                                let dynamicLink = eachCategory.name?.en
                                    .trim()
                                    .split(" ")
                                    .join("-");
                                return (
                                    <Link
                                        key={eachCategory._id}
                                        to={`#${dynamicLink.toLowerCase()}`}
                                        className="barbutton text-decoration-none"
                                    >
                                        {eachCategory?.name?.en}
                                    </Link>
                                );
                            })}
                    </div>

                </div>
                <div className="shop-page-hr-blue-line"></div>
            </div>
            {Array.isArray(shopData) &&
                shopData[0].children &&
                shopData[0].children.map((eachCategory, idx) => {
                    let dynamicId = eachCategory.name?.en
                        .trim()
                        .split(" ")
                        .join("-");
                    const styles = getStylesLan(
                        eachCategory,
                        "background_color",
                        "text_color"
                    );

                    return (
                        <ShowCategory
                            key={idx}
                            eachCategory={eachCategory}
                            dynamicId={dynamicId}
                            variants={variants}
                            styles={styles}
                        />
                    );
                })}
        </div>
    );
};
