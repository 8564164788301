import React, { useEffect, useState } from "react";
import snacksgif from "./gif/snacks.gif";
import snacksmile from "./gif/Smile426f.svg";
import "./snacksGif.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const SnacksGif = ({ promotionBannarData, styles }) => {
    const [showValue, setShowValue] = useState(window.innerWidth);
    useEffect(() => {
        const handleWidth = () => {
            setShowValue(window.innerWidth);
        };
        window.addEventListener("resize", handleWidth);

        return () => {
            window.removeEventListener("resize", handleWidth);
        };
    }, []);

    if (!promotionBannarData) {
        return;
    }
    return promotionBannarData?.promotion_banner_status ? (
        <div className="main-snack-container-relative">
            <div className="main-snack-banner" style={styles.fullBg}>
                {/* <div className=""> */}
                <div className=" d-flex row snake-banner">
                    <div
                        className={
                            showValue <= 780
                                ? "left-container"
                                : "left-container col-md-6"
                        }
                    >
                        {promotionBannarData?.promotion_title?.en && (
                            <h2 className="heading wow animate__animated animate__fadeInUp" style={styles.color}>
                                {promotionBannarData.promotion_title.en}
                            </h2>
                        )}
                        {showValue <= 780
                            ? promotionBannarData?.permotion_banner_img && (
                                <div className="mobile-image">
                                    <img
                                        src={
                                            promotionBannarData.permotion_banner_img
                                        }
                                        alt=""
                                    />
                                </div>
                            )
                            : ""}
                        {promotionBannarData?.promotion_description?.en && (
                            <p className="desc wow animate__animated animate__fadeInUp" style={styles.color}>
                                {
                                    promotionBannarData
                                        .promotion_description.en
                                }
                            </p>
                        )}
                        {promotionBannarData?.promotion_button_name?.en && (
                            <Link
                                to={
                                    promotionBannarData.promotion_button_link
                                }
                                className="olly-btn wow animate__animated animate__fadeInUp"
                                style={styles.color}
                            >
                                {
                                    promotionBannarData
                                        .promotion_button_name.en
                                }
                            </Link>
                        )}
                    </div>

                    {showValue <= 780
                        ? ""
                        : promotionBannarData?.permotion_banner_img && (
                            <div
                                className={
                                    showValue <= 780
                                        ? "right-container"
                                        : "right-container col-md-6"
                                }
                            >
                                <img
                                    className={
                                        showValue <= 780 ? "" : "w-full"
                                    }
                                    src={
                                        promotionBannarData.permotion_banner_img
                                    }
                                    alt=""
                                />
                            </div>
                        )}
                </div>
            </div>
        </div>
        // </div>
    ) : null;
};
